import { useCallback, useEffect, useState } from 'react'
import DBProperties from '../../../components/dashboard/DBProperties'
import { Center, Flex, Spinner } from '@chakra-ui/react'
import { FlexContainer } from '../../../components/containers/Global'
import DBSupport from '../../../components/dashboard/DBSupport'
import DBPromoCode from '../../../components/dashboard/DBPromoCode'
import DBSubscriptions from '../../../components/dashboard/DBSubscriptions'
import DBTransactions from '../../../components/dashboard/DBTransactions'

import { useAuthContext } from '../../../context/AuthProvider'
import { UserProperty, useGetUserQuery, User } from '../../../generated/graphql'

import { OnBoardingModal } from '../../../components/Modals'
import { useNavigate } from 'react-router-dom'
import { isBefore } from 'date-fns'
import { getDomain } from '../../../helpers'
import { AUTH_STORAGE_KEY, ONBOARDING_URL } from '../../../constants'
import LZString from 'lz-string'
import TermsAndConditionsModal from '../../../components/termsAndConditions'

const DashboardPage = (): JSX.Element => {
  const { user } = useAuthContext()

  const navigate = useNavigate()
  const [subscribedProperties, setSubscribedProperties] = useState<UserProperty[]>([])

  const [onboardingStepCompleted, setOnboardingStepCompleted] = useState(0)
  const [showInitModal, setShowInitModal] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [recentUserData, setRecentUserData] = useState<User>()

  const { refetch: fetchUserData } = useGetUserQuery({
    skip: true,
    variables: {
      email: user?.info?.email as string
    }
  })

  //Get the user data first time the page loads
  useEffect(() => {
    // console.log('Getting the user data')
    fetchUserData().then((res) => {
      setRecentUserData(res.data.getUser as User)
      // console.log('User data results =', res)
    })
  }, [])

  useEffect(() => {
    const latestUserData = recentUserData
    const loadData = async () => {
      if (latestUserData) {
        //Get the user data (if any)
        //Check if the user data was retrieved without errors
        if (latestUserData) {
          // console.log('User data is there with no errors')
          setLoadingData(false)
          const allProperties = latestUserData.properties ?? []
          //Delete all undefined properties
          const filteredProperties = allProperties.filter(
            (property) => property !== null && property !== undefined
          )

          const subscriptions = filteredProperties

          // only sort subscription if there is 2 or more properties
          if (subscriptions.length > 1) {
            subscriptions.sort((a, b) => {
              return Number(a?.recordId) > Number(b?.recordId) ? 1 : -1
            })
          }

          //Set the most recent property (first in the array)
          // if (filteredProperties[0] !== undefined && filteredProperties[0] !== null) {
          //   setMostRecentProperty(filteredProperties[0])
          // }

          //Sort the properties received
          // and only sort subscriptions when array contain 2 or more subscriptions
          const sortedProperties =
            subscriptions.length > 1
              ? subscriptions.slice().sort((a, b) => {
                  const recordIdA = a?.recordId ?? '' // Use an empty string as the default value if recordId is null or undefined.
                  const recordIdB = b?.recordId ?? '' // Use an empty string as the default value if recordId is null or undefined.

                  return Number(recordIdB) - Number(recordIdA)
                })
              : subscriptions

          setSubscribedProperties(sortedProperties as UserProperty[])

          setOnboardingStepCompleted(
            (latestUserData?.onboardingProgressCompletedStep as number) || 1
          )

          if (sortedProperties) {
            if (
              sortedProperties[0]?.installationProcessInitiated === false ||
              sortedProperties[0]?.installationProcessInitiated === null ||
              sortedProperties[0]?.installationProcessInitiated === undefined
            ) {
              if (sortedProperties.length === 1) {
                if (
                  latestUserData?.onboardingProgressCompletedStep !== null &&
                  (latestUserData?.onboardingProgressCompletedStep as number) >= 5
                ) {
                  const isActive = sortedProperties[0]?.active
                  const createdAt = sortedProperties[0]?.createdAt

                  if (createdAt) {
                    const createdAtDate = new Date(createdAt)
                    // This is the date of the new flow was activated
                    const dateOfNewFlow = new Date('2023-07-24')
                    const isBeforeNewFlow = isBefore(createdAtDate, dateOfNewFlow)

                    if (isBeforeNewFlow) setShowInitModal(false)
                    return
                  }

                  if (isActive) {
                    setShowInitModal(false)
                    return
                  }
                  setShowInitModal(true)
                } else {
                  handleContinue(latestUserData)
                  //navigate(`/user/onboarding/checkout-summary/${latestUserData.recordId}`)
                }
              }
            }
          }
        }
      }
    }
    loadData()
  }, [recentUserData, fetchUserData, navigate])

  const handleContinue = useCallback((userData: any) => {
    const user = userData

    const domain = getDomain()
    const secure = domain === '.localhost' ? false : true

    // Set tokens in cookies
    document.cookie = `${AUTH_STORAGE_KEY}=${LZString.compressToBase64(
      JSON.stringify(user?.auth?.accessToken)
    )}; domain=${domain}; path=/; secure=${secure}; SameSite=None`

    switch (user?.info?.onboardingProgressCompletedStep) {
      case 5:
        window.open(`${ONBOARDING_URL}/credit-check`, '_blank')
        break

      case 6:
        if (user?.info?.entityType === 'private') {
          window.open(
            `${ONBOARDING_URL}/private-check/check/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/business-check/check/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        }
        break

      case 7:
        if (
          user?.info?.properties?.[0]?.bankAccountNumber &&
          user?.info?.properties?.[0]?.bankAccountNumber !== ''
        ) {
          window.open(
            `${ONBOARDING_URL}/private-check/upload/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/private-check/banking/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        }
        break

        case 7.1:
          window.open(
          `${ONBOARDING_URL}/business-check/banking/${user?.info?.properties?.[0]?.recordId}`,
          '_blank'
        )

        break

      case 8:
        window.open(
          `${ONBOARDING_URL}/contract-summary/private/${user?.info?.properties?.[0]?.recordId}-${user?.info?.properties?.[0]?.orderNumber}`,
          '_blank'
        )
        break

      case 7.2:
      case 8.1:
        window.open(
          `${ONBOARDING_URL}/document-upload/business/${user?.info?.properties?.[0]?.recordId}`,
          '_blank'
        )
        break

      case 8.2:
        window.open(
          `${ONBOARDING_URL}/contract-summary/business/${user?.info?.properties?.[0]?.recordId}-${user?.info?.properties?.[0]?.orderNumber}`,
          '_blank'
        )
        break

      case 9:
        if (user?.info?.properties?.[0]?.creditCheckPassed === null) {
          window.open(
            `${ONBOARDING_URL}/document-upload/business/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/document-upload/private/${user?.info?.properties?.[0]?.recordId}`,
            '_blank'
          )
        }
        break
      default:
        //If the step could not be found, open the summary page 
        console.error('No onboarding step found', user?.info?.onboardingProgressCompletedStep)
        window.open(`${ONBOARDING_URL}/credit-check`, '_blank')
        break
    }
  }, [])

  if (loadingData)
    return (
      <Center w="full" h={'100vh'}>
        <Spinner>One minute while we retrieve your data</Spinner>
      </Center>
    )

  return (
    <>
      {/* Hero Section */}
      <DBProperties
        subscribedProperties={subscribedProperties}
        onboardingStepsCompleted={recentUserData?.onboardingProgressCompletedStep}
      />
      {/*Mid Section*/}
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        {/* Support Container */}
        {subscribedProperties.length >= 1 &&
        subscribedProperties.some((item) => item.orderStatus?.includes('Installation')) ? (
          <>
            <FlexContainer w={['100%', '100%', '100%', '50%']} h="auto" maxH={'auto'}>
              <DBSupport />
            </FlexContainer>
            {/* PromoCode Container */}
            <FlexContainer bgColor={'#ffff'} w={['100%', '100%', '100%', '50%']}>
              <DBPromoCode referralCode={user?.info.referralCode as string} />
            </FlexContainer>
          </>
        ) : (
          <FlexContainer bgColor={'#ffff'} w={['100%', '100%', '100%', '100%']}>
            <DBPromoCode referralCode={user?.info.referralCode as string} />
          </FlexContainer>
        )}
      </Flex>
      {/* End Section */}
      <Flex w="100%" gap="20px" direction={['column']}>
        {/* Subscriptions Container */}
        <FlexContainer w={['100%']} bgColor={'#ffff'} h="auto" maxH={'auto'}>
          <DBSubscriptions
            subscribedProperties={subscribedProperties}
            onboardingInprogress={recentUserData?.onboardingInProgress}
            onboardingStepsCompleted={recentUserData?.onboardingProgressCompletedStep}
          />
        </FlexContainer>
        {/* Transactions Container */}
        <FlexContainer
          w={['100%', '100%', '100%', '100%']}
          bgColor={'#ffff'}
          h="auto"
          maxH={'auto'}
          position={'relative'}
          overflow="hidden"
        >
          <DBTransactions />
        </FlexContainer>
      </Flex>

      {showInitModal && recentUserData && onboardingStepCompleted < 9 && !loadingData && (
        <OnBoardingModal
          isOpen={showInitModal}
          onClose={() => {}}
          recordId={subscribedProperties[0]?.recordId}
          step={recentUserData?.onboardingProgressCompletedStep}
          orderNumber={subscribedProperties[0]?.orderNumber}
        />
      )}
      {recentUserData && <TermsAndConditionsModal user={recentUserData} />}
    </>
  )
}

export default DashboardPage
