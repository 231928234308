import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Button,
  Heading,
  Text,
  Center,
  Highlight,
  Flex,
  Spinner,
  ResponsiveValue
} from '@chakra-ui/react'
import { useAuthContext } from '../../context/AuthProvider'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Maybe } from '../../types'
import { useCookies } from 'react-cookie'
import { AUTH_STORAGE_KEY, ONBOARDING_URL, USER_STORAGE_KEY } from '../../constants'
import { getDomain } from '../../helpers'
import LZString from 'lz-string'
import { ca } from 'date-fns/locale'

export type modalBasicProps = {
  isOpen: boolean
  onClose: () => void
}

export type ModalProps = {
  heading?: React.ReactNode
  size?:
    | ResponsiveValue<
        | (string & {})
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | 'full'
        | 'xs'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
      >
    | undefined
  children?: React.ReactNode
} & modalBasicProps

const ModalBasic: FC<ModalProps> = ({ children, heading, isOpen, size = 'lg', onClose }) => {
  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} size={size} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'}>
        {heading}
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  )
}

export default ModalBasic

export const DocumentSigningCompleteModal: FC<ModalProps> = ({
  children,
  heading,
  isOpen,
  size = 'lg',
  onClose
}) => {
  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} size={size} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'} gap={'25px'}>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          w="full"
          py={'25px'}
          rounded={'4px'}
          bgColor="brand.secondary"
        >
          <Text color={'brand.text.light'} fontWeight="meduim">
            {heading}
          </Text>
        </Flex>

        {children}
      </ModalContent>
    </Modal>
  )
}
export const DefaultModal: FC<ModalProps> = ({
  children,
  heading,
  isOpen,
  size = 'lg',
  onClose
}) => {
  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} size={size} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent
        p={'25px'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={'25px'}
        maxH={['full', 'full', '3xl', '3xl']}
        overflowY={'auto'}
        className="scrollbar"
      >
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          w="full"
          py={'25px'}
          rounded={'4px'}
          bgColor="brand.secondary"
        >
          <Text color={'brand.text.light'} fontWeight="meduim">
            {heading}
          </Text>
        </Flex>

        {children}
      </ModalContent>
    </Modal>
  )
}

type ErrorModalType = {
  errorTitle: string
  errorMsg: string
  size?: string
  children?: React.ReactNode
  cornerCloseBtn?: boolean
} & modalBasicProps

export const ErrorModal: FC<ErrorModalType> = ({
  isOpen,
  onClose,
  errorTitle,
  errorMsg,
  size = 'lg',
  cornerCloseBtn = 'true',
  children
}) => {
  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'} gap="25px">
        <Center bgColor={'brand.secondary'} rounded="8px" w="full" h="50px">
          <Heading as="h3" color={'brand.text.yellow'} fontSize="20px" fontWeight={500}>
            {errorTitle}
          </Heading>
        </Center>
        {children ? (
          children
        ) : (
          <>
            {' '}
            {cornerCloseBtn === true && <ModalCloseButton />}
            {errorMsg}
          </>
        )}

        <Button variant={'dark'} onClick={onClose}>
          Try Again
        </Button>
      </ModalContent>
    </Modal>
  )
}

type CookiesModalType = {
  cookiesTitle: string
  cookiesMsg: string
  size?: string
  children?: React.ReactNode
  cornerCloseBtn?: boolean
} & modalBasicProps

export const CookiesModal: FC<CookiesModalType> = ({
  isOpen,
  onClose,
  cookiesTitle,
  cookiesMsg,
  size = 'lg',
  cornerCloseBtn = 'true',
  children
}) => {
  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'} gap="25px">
        <Center bgColor={'brand.secondary'} rounded="8px" w="full" h="50px">
          <Heading as="h3" color={'brand.text.yellow'} fontSize="20px" fontWeight={500}>
            {cookiesTitle}
          </Heading>
        </Center>
        {children ? (
          children
        ) : (
          <>
            {' '}
            {cornerCloseBtn === true && <ModalCloseButton />}
            {cookiesMsg}
          </>
        )}

        <Button variant={'dark'} onClick={onClose}>
          Confirm
        </Button>
      </ModalContent>
    </Modal>
  )
}

type SuccessModalType = {
  successTitle: string
  successMsg: string
  email?: string
  buttonText?: string
  size?: string
} & modalBasicProps

export const SuccessModal: FC<SuccessModalType> = ({
  isOpen,
  onClose,
  successTitle,
  successMsg,
  buttonText = 'Got it!',
  email = '',
  size = 'lg'
}) => {
  const { resendVerificationEmail } = useAuthContext()
  const [isSending, setIsSending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'} gap="25px">
        <Center bgColor={'brand.secondary'} rounded="8px" w="full" h="50px">
          <Heading as="h3" color={'brand.text.yellow'} fontSize="20px" fontWeight={500}>
            {successTitle}
          </Heading>
        </Center>

        <Flex
          direction={'column'}
          gap={['50px', '25px', '25px', '25px']}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text color={'brand.text.primary'} w="full" textAlign={'center'}>
            <Highlight query={['check your email', email]} styles={{ fontWeight: 'bold' }}>
              {successMsg}
            </Highlight>
          </Text>
          <Center
            color={'brand.secondary'}
            bgColor={'brand.primary'}
            rounded="full"
            // p={4}
            w="125px"
            h="125px"
          >
            {isSending ? <Spinner size={'xl'} /> : <MdOutlineMarkEmailUnread size={75} />}
          </Center>
          {error && (
            <Text fontSize={'13px'} color={'brand.text.danger'}>
              {error}
            </Text>
          )}
          {success && (
            <Text fontSize={'13px'} color={'brand.text.success'}>
              {success}
            </Text>
          )}

          <Text fontSize={'13px'} color={'brand.text.primary'}>
            Didn't get the mail?{' '}
            <Button
              variant={'unstyled'}
              fontSize={'13px'}
              textDecoration={'underline'}
              onClick={async () => {
                setIsSending(true)
                setSuccess(null)
                setError(null)
                try {
                  resendVerificationEmail && (await resendVerificationEmail(email))
                  setSuccess('Please check your email for the verification link.')
                  setIsSending(false)
                } catch (e) {
                  let errorMessage = ''
                  setIsSending(false)
                  if (typeof e === 'string') {
                    errorMessage = e.toUpperCase()
                  } else if (e instanceof Error) {
                    errorMessage = e.message

                    setError(errorMessage)
                  }
                }
              }}
            >
              Send it again
            </Button>
          </Text>
        </Flex>

        <Button variant={'dark'} onClick={onClose} mb={10}>
          {buttonText}
        </Button>
      </ModalContent>
    </Modal>
  )
}

type modelstepType = {
  activeStep: string
  title: React.ReactNode
  children?: React.ReactNode
}

export type OnBoardingModalProps = {
  heading?: string
  size?: string
  children?: React.ReactNode
  step?: Maybe<number> | undefined
  recordId?: Maybe<string> | undefined
  orderNumber?: Maybe<string> | undefined
} & modalBasicProps
export const OnBoardingModal: FC<OnBoardingModalProps> = ({
  children,
  heading,
  isOpen,
  size = 'lg',
  step = '',
  recordId = '',
  orderNumber = '',
  onClose
}) => {
  const [cookies] = useCookies()
  const navigate = useNavigate()
  const [onboardingStep, setonBoardingStep] = useState('credit-type')
  const [onboardingStepCount, setonboardingStepCount] = useState<null | number>(0)
  const [activeStepContent, setActiveStepContent] = useState<modelstepType>({
    activeStep: 'subscription',
    title: (
      <Text color={'brand.text.light'}>
        <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
          Welcome to your GoSolr dashboard
        </Highlight>
      </Text>
    ),
    children: (
      <>
        {' '}
        <Heading as="h3" fontSize={'16px'}>
          Before you start exploring your brand new dashboard...
        </Heading>
        <Text fontSize={'14px'}>
          We need your help with confirming and finalising some personal details.
        </Text>
      </>
    )
  })

  const stepsArr = ['subscription', 'billing', 'documents', 'agreement', 'credit-check']

  const [testStep, setTestStep] = useState(0)

  const modalStepContentArr: modelstepType[] = [
    {
      activeStep: 'banking-details',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Confirm your banking details
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            Please provide us with your preferred debit order date and banking details.
          </Text>
        </>
      )
    },
    {
      activeStep: 'documents',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Choose your monthly billing date and upload required documents.
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            Flexibility is key. Choose one of the three billing dates that works for you.
          </Text>
        </>
      )
    },
    {
      activeStep: 'business-documents',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Upload required documents.
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>Please upload your required business documents.</Text>
        </>
      )
    },
    {
      activeStep: 'agreement',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Sign your agreement
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            Here’s the fine print. We have made your subscription agreement as easy to understand as
            possible, so no complicated jargon or hidden fees.
          </Text>
        </>
      )
    },
    {
      activeStep: 'credit-check',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Accept credit check
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            To ensure we can have a lasting relationship we will finalise the signup with a credit
            check.
          </Text>
        </>
      )
    },
    {
      activeStep: 'business-credit-check',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Accept credit check
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            To ensure we can have a lasting relationship we will finalise the signup with a credit
            check.
          </Text>
        </>
      )
    },
    {
      activeStep: 'trust-credit-check',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Accept credit check
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            To continue building a lasting relationship, we'll proceed with the next step of the
            onboarding process, which includes a credit check
          </Text>
        </>
      )
    },
    {
      activeStep: 'credit-type',
      title: (
        <Text color={'brand.text.light'}>
          <Highlight query={'solr'} styles={{ color: 'brand.text.yellow', fontWeight: 'meduim' }}>
            Select your credit type
          </Highlight>
        </Text>
      ),
      children: (
        <>
          <Text fontSize={'14px'}>
            Please choose whether you would like to have a personal or business credit check
            conducted.
          </Text>
        </>
      )
    }
  ]

  useEffect(() => {

    let activeStep: string = 'credit-type'
    switch (step) {
      case 1:
        activeStep = 'property-address'
        break
      case 2:
        activeStep = 'roof-location'
        break
      case 3:
        activeStep = 'electricity-needs'
        break
      case 4:
        activeStep = 'subscription-selection'
        break
      case 5:
        activeStep = 'credit-type'
        break
      case 6:
        activeStep = 'credit-check'
        break
      case 7.0:
      case 7:
        activeStep = 'banking-details'
        break
      case 7.1:
        activeStep = 'banking-details'
        break
      case 7.2:
        activeStep = 'business-documents' //TODO: We don't have a step for trust details at the moment. Default to business banking details
        //activeStep = 'trust-credit-check'
        break
      case 8.0:
        activeStep = 'documents'
        break
      case 8.1:
        activeStep = 'business-documents'
        break
      case 9:
        activeStep = 'onboarding Completed'
        break

      default:
        activeStep = 'checkout-summary'
        break
    }
    setonboardingStepCount(step as number)
    setonBoardingStep(activeStep)
    console.log(activeStep)
  }, [step])

  useEffect(() => {
    const selected = modalStepContentArr.find((item) => item.activeStep === onboardingStep)
    if(selected)
      setActiveStepContent(selected as modelstepType)
  }, [onboardingStep, testStep])

  const handleNavigation = () => {
    handleContinue()
  }

  const handleContinue = useCallback(() => {
    const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
      sessionStorage.getItem(USER_STORAGE_KEY)) as string
    const user = userStorage ? JSON.parse(userStorage) : {}

    const domain = getDomain()
    const secure = domain === '.localhost' ? false : true

    // Set tokens in cookies
    document.cookie = `${AUTH_STORAGE_KEY}=${LZString.compressToBase64(
      JSON.stringify(user?.auth?.accessToken)
    )}; domain=${domain}; path=/; secure=${secure}; SameSite=None`

    console.log("Modal Step", user?.info?.onboardingProgressCompletedStep)

    switch (user?.info?.onboardingProgressCompletedStep) {
      case 5:
        window.open(`${ONBOARDING_URL}/credit-check`, '_blank')
        break

      case 6:
        if (user?.info?.entityType === 'private') {
          window.open(
            `${ONBOARDING_URL}/private-check/check/${recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/business-check/check/${recordId}`,
            '_blank'
          )
        }
        break

      case 7:
        if (
          user?.info?.properties[-1]?.bankAccountNumber &&
          user?.info?.properties[-1]?.bankAccountNumber !== ''
        ) {
          window.open(
            `${ONBOARDING_URL}/private-check/upload/${recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/private-check/banking/${recordId}`,
            '_blank'
          )
        }
        break

      case 7.1:
        window.open(
          `${ONBOARDING_URL}/business-check/banking/${recordId}`,
          '_blank'
        )
        break
      case 7.2:
        window.open(
          `${ONBOARDING_URL}/document-upload/business/${recordId}`,
          '_blank'
        )
        break

      case 8:
        window.open(
          `${ONBOARDING_URL}/contract-summary/private/${recordId}-${orderNumber}`,
          '_blank'
        )
        break

      case 8.1:
        window.open(
          `${ONBOARDING_URL}/document-upload/business/${recordId}`,
          '_blank'
        )
        break

      case 8.2:
        window.open(
          `${ONBOARDING_URL}/contract-summary/business/${recordId}-${orderNumber}`,
          '_blank'
        )
        break

      case 9:
        if (user?.info?.properties[user.info.properties.length-1]?.creditCheckPassed === null) {
          window.open(
            `${ONBOARDING_URL}/document-upload/business/${recordId}`,
            '_blank'
          )
        } else {
          window.open(
            `${ONBOARDING_URL}/document-upload/private/${recordId}`,
            '_blank'
          )
        }
        break
      default:
        break
    }
  }, [])

  return (
    <Modal isCentered blockScrollOnMount={true} isOpen={isOpen} size={size} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent p={'25px'} alignItems={'center'} justifyContent={'space-between'}>
        <Flex direction={'column'} w="full" gap={'50px'} color={'brand.text.primary'}>
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            w="full"
            py={'25px'}
            rounded={'4px'}
            bgColor="brand.secondary"
            px={6}
          >
            {activeStepContent.title && activeStepContent.title}
          </Flex>
          {activeStepContent.children && activeStepContent.children}
          <Flex alignItems={'center'} justifyContent={'center'} w="full">
            <Button variant={'dark'} maxW="300px" px={'25px'} onClick={handleNavigation}>
              Let's go
            </Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
