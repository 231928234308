import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import PropertyHeader from '../../../components/Property/PropertyHeader'
import { useAuthContext } from '../../../context/AuthProvider'
import { Exact, useGetUserPropertyQuery, User, UserProperty } from '../../../generated/graphql'
import { useParams } from 'react-router-dom'
import PropertyStatsContainer from '../../../components/Property/PropertyStatsContainer'
import LoadingComponent from '../../../components/Loading'
import ApplicationProgressContainer from '../../../components/ApplicationProgress'

type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const PropertyPage = ({ setTitle }: Props): JSX.Element => {
  const { user } = useAuthContext()
  const { orderNumber } = useParams()
  const [property, setProperty] = useState<UserProperty>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    data: propertyData,
    loading: loadingProperties,
    refetch: fetchProperty
  } = useGetUserPropertyQuery({
    variables: {
      recordId: orderNumber as string
    }
  })

  useEffect(() => {
    setTitle(`Property`)

    const fetchSelectedProperty = async () => {
      const recordId = orderNumber as string
      // const loadedProperty = propertyData?.getUserProperty as UserProperty
      const { data, loading } = await fetchProperty(
        recordId as Partial<Exact<{ recordId: string }>>
      )
      setIsLoading(loading)
      const loadedProperty = data.getUserProperty as UserProperty

      setProperty(loadedProperty)
    }
    fetchSelectedProperty()
  }, [])

  if (isLoading) {
    return <LoadingComponent loadingText="Loading Property Data" />
  }

  if (!isLoading && property?.fullAddress) {
    return (
      <>
        {/* Property Header */}
        <PropertyHeader property={property as UserProperty} orderNumber={orderNumber} />
        {/* Property Stats */}
        {property.installationComplete || property.installationProcessInitiated ? (
          <PropertyStatsContainer property={property as UserProperty} />
        ) : (
          <ApplicationProgressContainer
            property={property}
            onboardingStepsCompleted={
              user?.info?.onboardingProgressCompletedStep
                ? Number(user?.info?.onboardingProgressCompletedStep)
                : 1
            }
          />
        )}
        {/* <PropertyMiscDetails property={property} /> */}
      </>
    )
  }

  return (
    <Flex>
      <Text>No property data available</Text>
    </Flex>
  )
}

export default PropertyPage
